// 負載+自發自用+儲能+遠程綠電轉供 VPP0008
export const origin = {
    monthAllWheeling: 567.88, //月度轉供量
    yearAllWheeling: 20567.88, //年度轉供量
    monthWheelingLimit: 2500, //月度轉供量上限
    yearWheelingLimit: 30000, //年度轉供量上限
    carbon: 226.88, //當月減碳量
    re: 0.99, //RE
    maxDemand: 19.04, //最大需量
    saveFine: 3091.55, //契約容量節省
    selfSaveBill: 625.45, //綠能節省
    saveBill: 37.09, //電費節省
    totalSave: 3128.64, //總和節省
    excludeDispatchBill: 6506.44, //原始電費
}

export const scene1 = {
    monthWheelingLimit: 2500, //月度轉供量上限
    yearWheelingLimit: 30000, //年度轉供量上限
    re: 0.9, //RE
}

//負載+自發自用+儲能 VPP0004
export const scene2 = {
    carbon: 93.27, //當月減碳量
    re: 0.58, //RE
    maxDemand: 18.42, //最大需量
    saveFine: 0, //契約容量節省
    selfSaveBill: 0, //綠能節省
    saveBill: 0, //電費節省
    totalSave: 0 //總和節省
}

//負載+儲能+遠程綠電轉供
export const scene3 = {
    re: 0.81, //RE
    monthWheelingLimit: 2500, //月度轉供量上限
    yearWheelingLimit: 30000, //年度轉供量上限
}

//負載+充電樁
export const scene4 = {
    carbon: 0, //當月減碳量
    re: 0, //RE
    maxDemand: 0, //最大需量
    saveFine: 0, //契約容量節省
    selfSaveBill: 0, //綠能節省
    saveBill: 0, //電費節省
    totalSave: 0 //總和節省
}

//負載+儲能 VPP0005
export const scene5 = {
    carbon: 0, //當月減碳量
    re: 0, //RE
    maxDemand: 18.43, //最大需量
    saveFine: 0, //契約容量節省
    selfSaveBill: 0, //綠能節省
    saveBill: 0, //電費節省
    totalSave: 0 //總和節省
}